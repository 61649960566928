import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/nonun/...Active Code/new-gatsby-site/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/df0d88825d9337db863967773696a4fc/857b3/books-vs-audiobooks.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.5%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAED/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEAMQAAAB1iGAP//EABkQAQADAQEAAAAAAAAAAAAAAAEAETECIf/aAAgBAQABBQI8HItNVOt//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAFxAAAwEAAAAAAAAAAAAAAAAAAAEQMf/aAAgBAQAGPwJ1Gz//xAAaEAADAQEBAQAAAAAAAAAAAAABESEAMRCB/9oACAEBAAE/IYpqoGB8fMRcYsda01Xj/9oADAMBAAIAAwAAABC0L//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8QP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8QP//EAB0QAQEAAQQDAAAAAAAAAAAAAAERACExUWFBkbH/2gAIAQEAAT8QYSYTbt8e/uJrQy1udYxwxikhRw6ZUqFooXHF3rn/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "books-vs-audiobooks",
            "title": "books-vs-audiobooks",
            "src": "/static/df0d88825d9337db863967773696a4fc/4b190/books-vs-audiobooks.jpg",
            "srcSet": ["/static/df0d88825d9337db863967773696a4fc/e07e9/books-vs-audiobooks.jpg 200w", "/static/df0d88825d9337db863967773696a4fc/066f9/books-vs-audiobooks.jpg 400w", "/static/df0d88825d9337db863967773696a4fc/4b190/books-vs-audiobooks.jpg 800w", "/static/df0d88825d9337db863967773696a4fc/e5166/books-vs-audiobooks.jpg 1200w", "/static/df0d88825d9337db863967773696a4fc/b17f8/books-vs-audiobooks.jpg 1600w", "/static/df0d88825d9337db863967773696a4fc/857b3/books-vs-audiobooks.jpg 6000w"],
            "sizes": "(max-width: 800px) 100vw, 800px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h1>{`Introduction`}</h1>
    <ul>
      <li parentName="ul">{`So, as you may or may not know, I love to read books. I have read a number of Productivity books, physics books and a few psychology books also.`}</li>
      <li parentName="ul">{`I think that there is one thing that is often not acknowledged when we are reading a book. Reading is not the most important part. But, understanding and actually applying the concepts in a book to the real life are more important.`}</li>
      <li parentName="ul">{`There are millions of books. All of which are biding for your attention. You have to make a conscious effort whether you actually want to read a book.`}</li>
    </ul>
    <h1>{`The Argument`}</h1>
    <ul>
      <li parentName="ul">{`It is clear that audiobooks are worse in the aspect of actually taking in deep concepts in a book say "The Courage to be Disliked". The concepts are deep and you really need a way to take all that stuff in. It is harder to take notes also. Their advantage is that you can easily consume a lot of books.`}</li>
      <li parentName="ul">{`Books solve the problem of deep understanding and taking in the concepts. But, they are not as easy to read as audiobooks are. You can listen to audiobooks whenever you are doing a mundane task or something boring. But, with a book, you have to actually sit down and read it.`}</li>
      <li parentName="ul">{`This is where the debate comes in. Why audiobooks are superior! Why books are superior! and that is not even to say about the debate for physical books and e-books.`}</li>
      <li parentName="ul">{`I won't be going into the physics vs e-books debate here.`}</li>
    </ul>
    <h1>{`The Signal to Noise Ratio`}</h1>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`This is where I would like to introduce a concept called `}<em parentName="p">{`The Signal to Noise Ratio`}</em>{`. This essentially means the amount of signal(i.e. the amount of real valuable content) divided by the noise(i.e. the amount of fluff or padding that is added to a book).`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`I am a person who loves to use both books and audiobooks. Whenever someone recommends me a book, I pick up a sample of the audiobook from audible. If I feel like it is right up my alley, then I give it a listen. If I feel like this book is deep and requires a deeper level of commitment, then I buy the book. I still finished the audiobook. The reason is that it allows me to identify in which parts of the book, the Signal to Noise Ratio is high and is worth my full attention. It is sort of a build up for the mind.`}</p>
      </li>
    </ul>
    <h1>{`Conclusion`}</h1>
    <ul>
      <li parentName="ul">{`When deciding whether, I should buy the book or not after consuming the audiobook, I ask myself two questions, is the topic right up my alley? and what is the signal to noise ratio of the book? If the answer to the first question is yes and the answer to the second question is also yes, then I will read the book. If, the answer to the second one is no, then I will re-listen to the audiobook but no read the book.`}</li>
      <li parentName="ul">{`One of the books that I will highly recommend is "The Courage to be Disliked". That is a book that is worth listening to and reading as well. I will make sure to publish my notes on the website. If you have completed that book then you may want to check out "The Courage to be Happy". It is the sequel to the first book and is equally awesome.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      